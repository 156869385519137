import { Classes } from "@blueprintjs/core";
import { RoleTypeEnum } from "@superblocksteam/shared";
import { Tooltip, Typography } from "antd";
import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

import styled from "styled-components";
import {
  DropdownOption,
  RecommendedSingleDropdown,
} from "components/ui/RecommendedSingleDropdown";
import { VIEW_ROLES } from "constants/rbac";
import { useAuthorizationCheck } from "hooks/ui/rbac/useAuthorizationCheck";
import { SUPPORT_EMAIL_ADDRESS } from "legacy/constants/routes";
import { getCurrentOrgId } from "legacy/selectors/organizationSelectors";
import {
  useDeleteOrganizationRoleMutation,
  useListRolesQuery,
  useUpdateOrganizationRoleMutation,
} from "store/slices/reduxApi/rbac";
import { colors } from "styles/colors";
import {
  sendErrorUINotification,
  sendSuccessUINotification,
} from "utils/notification";

const StyledWrapper = styled.div`
  max-width: 150px;
  min-width: 120px;
  .${Classes.INPUT} {
    border-color: transparent;
  }
  &:hover {
    .${Classes.INPUT} {
      border-color: ${colors.GREY_100};
    }
  }
`;

const OWNER_ROLE_NAME = "Owner";

export const RoleDropdown = ({
  currentRole,
  currentRoleName,
  principalType,
  principalId,
  onRoleChanged,
  isClearable,
  isDisabled,
  currentRoleAssignmentId,
}: {
  currentRole: string;
  currentRoleName: string;
  principalType: "group" | "user";
  principalId: string;
  onRoleChanged: (
    principalId: string,
    updates: Record<string, string | null>,
  ) => void;
  isClearable?: boolean;
  isDisabled?: boolean;
  currentRoleAssignmentId: string;
}) => {
  const organizationId = useSelector(getCurrentOrgId);
  const [canViewRoles] = useAuthorizationCheck([VIEW_ROLES]);

  const { data: fetchedRoles, isError } = useListRolesQuery(
    {
      type: RoleTypeEnum.ORGANIZATION,
      organizationId,
    },
    {
      skip: !canViewRoles,
    },
  );

  const currentRoleNameFromOptions = useMemo(
    () => fetchedRoles?.find((role) => role.id === currentRole)?.name,
    [currentRole, fetchedRoles],
  );
  const isOwnerUser =
    principalType === "user" && currentRoleNameFromOptions === OWNER_ROLE_NAME;

  const roleOptions = useMemo(() => {
    if (!isError && fetchedRoles) {
      return fetchedRoles
        .filter((role) => isOwnerUser || role.name !== OWNER_ROLE_NAME)
        .map((role) => {
          return {
            key: role.name,
            value: role.id,
            displayName: role.name,
            subText: role.description,
          };
        });
    } else if (currentRoleName) {
      // if users has no read roles permission, show only the current role
      return [
        {
          key: currentRole,
          value: currentRole,
          displayName: currentRoleName,
        },
      ];
    }
  }, [currentRole, currentRoleName, fetchedRoles, isError, isOwnerUser]);

  const [updateRole, { isLoading: isUpdating }] =
    useUpdateOrganizationRoleMutation();

  const [deleteRole, { isLoading: isDeleting }] =
    useDeleteOrganizationRoleMutation();

  const onUpdateRole = useCallback(
    async (
      selectedItem?: DropdownOption,
      event?: React.SyntheticEvent<HTMLElement>,
    ) => {
      event?.stopPropagation();
      try {
        if (!selectedItem) {
          await deleteRole({
            principalId,
            principalType,
            role: currentRoleAssignmentId,
            organizationId,
          }).unwrap();
          sendSuccessUINotification({
            message: "Role removed",
            duration: 5,
          });
          onRoleChanged(principalId, {
            roleId: null,
            roleAssignmentId: null,
          });
          return;
        } else {
          const response = await updateRole({
            principalId,
            principalType,
            role: selectedItem ? selectedItem.value : null,
            organizationId: organizationId,
          }).unwrap();

          sendSuccessUINotification({
            message: "Role updated",
            duration: 5,
          });
          onRoleChanged(principalId, {
            roleId: response.data.assignmentId,
            roleAssignmentId: response.data.id,
          });
        }
      } catch (e: any) {
        sendErrorUINotification({
          key: "update-role",
          message: e?.error || e?.error?.message || "Failed to update role",
          duration: 5,
        });
      }
    },
    [
      updateRole,
      onRoleChanged,
      currentRoleAssignmentId,
      deleteRole,
      principalId,
      principalType,
      organizationId,
    ],
  );

  const disableUpdate = isUpdating || isDeleting;

  return (
    <Tooltip
      title={
        isOwnerUser ? (
          <div>
            Owner role changes are restricted.{" "}
            <Typography.Link
              href={`mailto:${SUPPORT_EMAIL_ADDRESS}`}
              target="_blank"
            >
              {" "}
              Contact support
            </Typography.Link>{" "}
            to reassign this role.
          </div>
        ) : undefined
      }
    >
      <StyledWrapper>
        <RecommendedSingleDropdown
          options={roleOptions ?? []}
          value={currentRole}
          onChange={onUpdateRole}
          disabled={isDisabled || disableUpdate || isOwnerUser}
          allowClearing={isClearable}
          placeholder="No role"
          popoverProps={{
            matchTargetWidth: false,
          }}
          minDropdownWidth={{
            width: 340,
            atLeastTargetWidth: true,
          }}
        />
      </StyledWrapper>
    </Tooltip>
  );
};
