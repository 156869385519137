import {
  RoleDto,
  RoleTypeEnum,
  snakeCaseToDisplay,
} from "@superblocksteam/shared";
import { Tooltip } from "antd";
import { RangeTuple } from "fuse.js";
import { get } from "lodash";
import React from "react";
import { Row } from "react-table";
import { Checkbox } from "components/ui/Checkbox";
import { RecColumn } from "components/ui/RecommendedTable";
import { HighlightedResult } from "components/ui/SearchSection";
import { colors } from "styles/colors";
import { USER_FRIENDLY_RESOURCE_NAMES } from "./constants";

enum CheckboxState {
  Checked,
  Unchecked,
  Indeterminate,
}

export const getRoleTypeName = (roleType: RoleTypeEnum) => {
  return USER_FRIENDLY_RESOURCE_NAMES[roleType] ?? snakeCaseToDisplay(roleType);
};

export type NestedRoleTableItem = {
  name: string;
  description: string;
  actionType: string;
  resourceType: string;
  highlights?: Array<[number, number]>;
} & Record<string, CheckboxState>;

export type RoleTableItem = Record<string, CheckboxState> & {
  name: string;
  resourceType: string;
  highlights?: Array<[number, number]>;
  children: Array<NestedRoleTableItem>;
};

const CheckboxCell = ({ value }: { value: CheckboxState }) => {
  return (
    <Checkbox
      checked={value === CheckboxState.Checked}
      partialChecked={value === CheckboxState.Indeterminate}
      disabled={true} // todo: do not disable / handle updates for non-built in roles
    />
  );
};

const PERMISSION_COL_WIDTH = 250;
const PERMISSION_COLUMN: RecColumn<RoleTableItem> = {
  Header: "Permissions",
  accessor: "name",
  hidden: false,
  width: PERMISSION_COL_WIDTH,
  minWidth: PERMISSION_COL_WIDTH,
  disableSortBy: true,
  Cell: ({
    value,
    row,
  }: {
    value: string | CheckboxState;
    row: Row<RoleTableItem>;
  }) => {
    let style: React.CSSProperties = {};
    if (row.canExpand) {
      style = {
        fontSize: "12px",
        fontWeight: 500,
        color: colors.GREY_700,
      };
    } else {
      style = {
        fontFamily: "Roboto Mono",
        fontSize: "11px",
        fontWeight: 400,
        color: colors.GREY_700,
      };
    }
    return (
      <Tooltip title={row.original.description}>
        <span style={style}>
          <HighlightedResult
            value={String(value)}
            highlights={row.original.highlights}
          />
        </span>
      </Tooltip>
    );
  },
};

const sortOrder: Record<string, number> = {
  Owner: 0,
  Admin: 1,
  Developer: 2,
  "End-User": 3,
  Max: 4,
};
export function getRoleTableColumns(
  data: undefined | Array<RoleDto>,
  type: "organization" | "resource",
): RecColumn<any>[] {
  if (!data) return [];
  let roleColumns = data.map((role) => {
    return {
      Header: (
        <Tooltip title={role.description}>
          <span
            style={{
              borderBottom: `1px dashed ${colors.GREY_200}`,
              lineHeight: "16px",
            }}
          >
            {role.name}
          </span>
        </Tooltip>
      ),
      accessor: role.id,
      hidden: false,
      Cell: CheckboxCell,
      disableSortBy: true,
      name: role.name,
      width: `calc((100% - ${PERMISSION_COL_WIDTH}px) / ${data.length})`,
    };
  });

  roleColumns = roleColumns.sort((a, b) => {
    return (
      (sortOrder[a.name] ?? sortOrder.Max) -
      (sortOrder[b.name] ?? sortOrder.Max)
    );
  });

  return [
    {
      Header:
        type === "organization" ? (
          <span style={{ marginLeft: 12 }}>Permissions</span>
        ) : (
          "Permissions"
        ),
      ...PERMISSION_COLUMN,
    },
    ...roleColumns,
  ];
}

type PermissionObject = { description: string } & Record<string, CheckboxState>;
type RoleObject = {
  actions: Record<string, PermissionObject>;
  name: string;
  resourceType: string;
};

export function formatNestedRoleTableData(
  data: RoleDto[] | undefined,
  columns: RecColumn<RoleTableItem>[],
): RoleTableItem[] {
  if (!data) return [];

  const rowDataMap = new Map<string, RoleObject>();

  // Group the data based on the resource type and then by action type
  for (const role of data) {
    if (!role.permissions) continue;

    for (const permission of role.permissions) {
      const { resourceType, actionType, description } = permission;
      const resourceTypeShort = resourceType.split(".")[0];
      const permissionName = `${resourceType}:${actionType}`;

      const entry = rowDataMap.get(resourceTypeShort) || {
        resourceType,
        name:
          USER_FRIENDLY_RESOURCE_NAMES[resourceTypeShort] ??
          snakeCaseToDisplay(resourceTypeShort),
        actions: {},
      };

      entry.actions[permissionName] = {
        ...entry.actions[permissionName],
        description,
        [role.id]: CheckboxState.Checked,
      } as PermissionObject;

      rowDataMap.set(resourceTypeShort, entry);
    }
  }

  // Create array from the map
  return Array.from(rowDataMap.values())
    .map((entry) => {
      const arrayEntry = {
        name: entry.name,
        resourceType: entry.resourceType,
        children: Object.entries(entry.actions)
          .map(([permissionName, values]) => {
            const [resourceType, actionType] = permissionName.split(":");
            return {
              name: permissionName,
              actionType,
              resourceType,
              ...values,
            } as NestedRoleTableItem;
          })
          .sort((a, b) => a.name.localeCompare(b.name)),
      } as RoleTableItem;

      // Set checkbox states for columns
      for (const column of columns) {
        if (typeof column.accessor !== "string" || column.accessor === "name")
          continue;

        const accessor = column.accessor as string;
        const allChecked = arrayEntry.children.every(
          (child) => child[accessor] === CheckboxState.Checked,
        );
        const someChecked = arrayEntry.children.some(
          (child) => child[accessor] === CheckboxState.Checked,
        );

        arrayEntry[accessor] = allChecked
          ? CheckboxState.Checked
          : someChecked
          ? CheckboxState.Indeterminate
          : CheckboxState.Unchecked;
      }

      return arrayEntry;
    })
    .sort((a, b) => a.name.localeCompare(b.name));
}

export function formatRoleTableData(
  data: RoleDto[] | undefined,
): Array<NestedRoleTableItem> {
  if (!data) return [];
  const permissionsMap: Record<string, NestedRoleTableItem> = {};
  for (const role of data) {
    if (!role.permissions) continue;
    for (const permission of role.permissions) {
      const { actionType, resourceType, description } = permission;
      const name = `${resourceType}:${actionType}`;
      const entry = get(permissionsMap, name) ?? {
        actionType,
        resourceType,
        name: `${resourceType}:${actionType}`,
        description,
      };
      entry[role.id] = CheckboxState.Checked;
      permissionsMap[name] = entry;
    }
  }
  return Object.values(permissionsMap).sort((a, b) =>
    a.name.localeCompare(b.name),
  );
}

export const filterAndTransformHighights = (
  searchTerm: string,
  indices?: readonly RangeTuple[],
) => {
  if (!indices) return indices;
  return indices
    .map(([start, end]) => {
      if (end - start === searchTerm.length) {
        return [start, end];
      } else if (end - start === searchTerm.length - 1) {
        // for some reason, full word matches are not including the last letter, so
        // we want to filter out non-full matches + add a character to the end of full-word matches
        return [start, end + 1];
      }
      return null;
    })
    .filter(Boolean) as Array<[number, number]>;
};
