import {
  ActionTypeEnum,
  ResourceTypeEnum,
  RoleTypeEnum,
} from "@superblocksteam/shared";

export const OWNER_ROLE_NAME = "Owner";

export const ROLE_TYPES: Array<RoleTypeEnum> = [
  RoleTypeEnum.APPLICATIONS,
  RoleTypeEnum.INTEGRATIONS,
  // RoleTypeEnum.PROFILES,
  RoleTypeEnum.SCHEDULED_JOBS,
  // RoleTypeEnum.SECRETS_STORES,
  RoleTypeEnum.WORKFLOWS,
];

/* Org level permissions */
export const VIEW_ACCESS_TOKENS = {
  resourceType: ResourceTypeEnum.ACCESS_TOKENS,
  action: ActionTypeEnum.READ,
};

export const MANAGE_ACCESS_TOKENS = {
  resourceType: ResourceTypeEnum.ACCESS_TOKENS,
  action: ActionTypeEnum.MANAGE,
};

export const MANAGE_PROFILES = {
  resourceType: ResourceTypeEnum.PROFILES,
  action: ActionTypeEnum.MANAGE,
};

export const VIEW_PROFILES = {
  resourceType: ResourceTypeEnum.PROFILES,
  action: ActionTypeEnum.MANAGE, // There is no READ permission so we still check MANAGE
  preRbacValue: true, // Pre-RBAC, all users could view profiles
};

export const VIEW_REPOSITORIES = {
  resourceType: ResourceTypeEnum.REPOSITORIES,
  action: ActionTypeEnum.READ,
  preRbacValue: true,
};

export const MANAGE_REPOSITORIES = {
  resourceType: ResourceTypeEnum.REPOSITORIES,
  action: ActionTypeEnum.MANAGE,
  preRbacValue: true,
};

export const VIEW_OBSERVABILITY = {
  resourceType: ResourceTypeEnum.LOGS_STREAMS,
  action: ActionTypeEnum.READ,
  preRbacValue: true,
};

export const MANAGE_OBSERVABILITY = {
  resourceType: ResourceTypeEnum.LOGS_STREAMS,
  action: ActionTypeEnum.MANAGE,
};

export const VIEW_AGENTS = {
  resourceType: ResourceTypeEnum.AGENTS,
  action: ActionTypeEnum.READ,
};

export const MANAGE_AGENTS = {
  resourceType: ResourceTypeEnum.AGENTS,
  action: ActionTypeEnum.MANAGE,
};

export const MANAGE_ORGANIZATION = {
  resourceType: ResourceTypeEnum.ORGANIZATION,
  action: ActionTypeEnum.MANAGE,
  preRbacValue: true,
};

/* Users and groups permissions */
export const VIEW_USERS = {
  resourceType: ResourceTypeEnum.ORGANIZATION_USERS,
  action: ActionTypeEnum.READ,
  preRbacValue: true,
};

export const MANAGE_USERS = {
  resourceType: ResourceTypeEnum.ORGANIZATION_USERS,
  action: ActionTypeEnum.MANAGE,
};

export const VIEW_GROUPS = {
  resourceType: ResourceTypeEnum.GROUPS,
  action: ActionTypeEnum.READ,
  preRbacValue: true,
};

export const MANAGE_GROUPS = {
  resourceType: ResourceTypeEnum.GROUPS,
  action: ActionTypeEnum.MANAGE,
};

export const VIEW_GROUP_MEMBERS = {
  resourceType: ResourceTypeEnum.GROUPS_MEMBERS,
  action: ActionTypeEnum.READ,
  preRbacValue: true,
};

export const MANAGE_GROUP_MEMBERS = {
  resourceType: ResourceTypeEnum.GROUPS_MEMBERS,
  action: ActionTypeEnum.MANAGE,
  preRbacValue: true,
};

export const VIEW_ROLES = {
  resourceType: ResourceTypeEnum.ROLES,
  action: ActionTypeEnum.READ,
  preRbacValue: true,
};

export const MANAGE_ROLE_MEMBERS = {
  resourceType: ResourceTypeEnum.ROLES_MEMBERS,
  action: ActionTypeEnum.MANAGE,
};

export const READ_LOGS = {
  resourceType: ResourceTypeEnum.LOGS,
  action: ActionTypeEnum.READ,
  preRbacValue: true,
};

export const MANAGE_FOLDERS = {
  resourceType: ResourceTypeEnum.FOLDERS,
  action: ActionTypeEnum.MANAGE,
  preRbacValue: true,
};

/* Integrations and secret stores */
export const VIEW_SECRET_STORES = {
  resourceType: ResourceTypeEnum.SECRETS_STORES,
  action: ActionTypeEnum.CREATE,
};

export const CREATE_INTEGRATION = {
  resourceType: ResourceTypeEnum.INTEGRATIONS,
  action: ActionTypeEnum.CREATE,
};

export const CREATE_SECRET_STORE = {
  resourceType: ResourceTypeEnum.SECRETS_STORES,
  action: ActionTypeEnum.CREATE,
};

/* Entities */
export const CREATE_APPLICATIONS = {
  resourceType: ResourceTypeEnum.APPLICATIONS,
  action: ActionTypeEnum.CREATE,
  preRbacValue: true,
};

export const CREATE_WORKFLOWS = {
  resourceType: ResourceTypeEnum.WORKFLOWS,
  action: ActionTypeEnum.CREATE,
  preRbacValue: true,
};

export const CREATE_JOBS = {
  resourceType: ResourceTypeEnum.SCHEDULED_JOBS,
  action: ActionTypeEnum.CREATE,
  preRbacValue: true,
};
