import {
  DefaultGroupName,
  GroupDetail,
  GroupMember,
  GroupMemberStatus,
  GroupType,
  OrganizationUserDto,
  OrganizationUserStatus,
} from "@superblocksteam/shared";
import { Tooltip } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import AddButton from "components/ui/AddButton";
import { DeleteModal } from "components/ui/Modal";
import RecommendedTable, { RecColumn } from "components/ui/RecommendedTable";
import {
  SearchContainer,
  SearchInput,
  filterBySearch,
} from "components/ui/SearchSection";
import { MANAGE_GROUP_MEMBERS } from "constants/rbac";
import { useDebounce, useFeatureFlag } from "hooks/ui";
import { useAuthorizationCheck } from "hooks/ui/rbac/useAuthorizationCheck";
import { getCurrentUser } from "legacy/selectors/usersSelectors";
import { Invitee } from "pages/Permissions/constants";
import { deactivateUserRequest, getUsersRequest } from "pages/Users/client";
import InviteOrgUserModal from "pages/components/invitation/InviteOrgUserModal";
import { Flag } from "store/slices/featureFlags";
import { selectOnlyOrganizationId } from "store/slices/organizations";
import { nameWithEmail } from "store/utils/group";
import { styleAsClass } from "styles/styleAsClass";
import { sendSuccessUINotification } from "utils/notification";
import NewMemberModal from "./NewMemberModal";
import NewMemberModalV2 from "./NewMemberModalV2";
import {
  groupMemberCompareFn,
  MemberToRender,
  convertToMemberToRender,
} from "./constants";

const NameStyle = styleAsClass`
  max-width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

type ColType = RecColumn<MemberToRender>;

const MemberList = ({
  group,
  groupLoading,
}: {
  group: GroupDetail | undefined; // could be
  groupLoading: boolean;
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const onSearchChangeDebounced = useDebounce(
    (e) => setSearchTerm(e.target.value),
    200,
  );
  const orgId = useSelector(selectOnlyOrganizationId);
  const [members, setMembers] = useState<MemberToRender[]>([]);

  useEffect(() => {
    if (group?.members) {
      setMembers(group.members.map(convertToMemberToRender));
    }
  }, [group?.members]);

  const [orgUsers, setOrgUsers] = useState<OrganizationUserDto[]>([]);

  const [addModalOpen, setAddModalOpen] = useState(false);
  const onAddCancel = useCallback(() => {
    setAddModalOpen(false);
    setUserToModify(undefined);
  }, []);

  useEffect(() => {
    async function loadOrgUsers() {
      if (!orgId) return;
      const users = await getUsersRequest(orgId);
      if (users) {
        setOrgUsers(users);
      }
    }
    loadOrgUsers();
  }, [orgId]);

  const onUsersAdded = useCallback(
    (newMembers: OrganizationUserDto[], operation: "replace" | "concat") => {
      setMembers((prev) =>
        operation === "replace"
          ? newMembers.map(convertToMemberToRender)
          : [...prev, ...newMembers.map(convertToMemberToRender)],
      );
    },
    [],
  );

  const invitees: Invitee[] = useMemo(() => {
    const groupMemberSet = new Set(
      members.map((groupMember) => groupMember.email),
    );
    return orgUsers
      .filter((orgUser) => !groupMemberSet.has(orgUser.email))
      .filter((user) => user.status !== OrganizationUserStatus.INACTIVE)
      .sort((a, b) => a.email.localeCompare(b.email))
      .map((orgUser) => ({
        ...orgUser,
        type: "user",
        id: orgUser.email, // add members endpoint use emails
      }));
  }, [members, orgUsers]);

  const filteredMembers = useMemo(() => {
    let filtered = members.filter(
      (member) => member.status !== GroupMemberStatus.DEACTIVATED,
    );
    filtered =
      searchTerm.length > 1
        ? filtered.filter((member) =>
            filterBySearch(member, searchTerm, ["name", "email"]),
          )
        : filtered;

    return filtered.sort(groupMemberCompareFn);
  }, [members, searchTerm]);

  const [userToModify, setUserToModify] = useState<MemberToRender | undefined>(
    undefined,
  );

  const currentUser = useSelector(getCurrentUser);

  const columns: ColType[] = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ value, cell }) => (
          <div className={NameStyle}>
            {cell.row.original.email === currentUser?.email
              ? `${value} (You)`
              : value}
          </div>
        ),
      },
      {
        Header: "Email",
        accessor: "email",
      },
    ],
    [currentUser?.email],
  );

  const [canEditGroupMembers] = useAuthorizationCheck([MANAGE_GROUP_MEMBERS]);

  const removeTooltip = useCallback(
    (group: GroupDetail | undefined, member: GroupMember) => {
      if (!canEditGroupMembers) {
        return "You do not have permission to remove group members.";
      }
      // if we cannot remove a group, return tooltip text
      // validate the following:
      // - group and currentUser.id are not empty
      // - we have edit permissions on this group
      // - target member is not deactivated
      // - if the group is ALL_USERS and we are the target member, reject
      // - if the group is ADMIN and there is exactly 1 active member, reject (to prevent superusers/admins from accidentally removing all internal admin access in the org)
      if (!group || !currentUser?.id) {
        return group?.type === GroupType.ADMIN
          ? `Contact an administrator to remove members from the ${DefaultGroupName.ADMIN} group`
          : `Unauthorized`;
      }

      switch (group?.type) {
        case GroupType.ADMIN:
          return members.filter(
            (groupMember) => groupMember.status === GroupMemberStatus.JOINED,
          ).length === 1
            ? `Cannot remove the only member from the ${DefaultGroupName.ADMIN} group`
            : "";
        case GroupType.EVERYONE:
        case GroupType.ALL_USERS:
          if (member.id === currentUser.id) {
            return `You cannot remove yourself from the organization.`;
          }
      }
    },
    [canEditGroupMembers, members, currentUser?.id],
  );

  const menuItems = useCallback(
    (user: MemberToRender) => {
      const tooltip = removeTooltip(group, user);
      return [
        {
          key: "1",
          label: (
            <Tooltip title={tooltip} placement="left">
              <div>Remove from group</div>
            </Tooltip>
          ),
          onClick: () => {
            setUserToModify(user);
            setDeleteModalOpen(true);
          },
          "data-test": "remove-user-button",
          disabled: Boolean(tooltip),
        },
      ];
    },
    [group, removeTooltip],
  );

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const [deleteError, setDeleteError] = useState<string>();
  const [isDeleting, setIsDeleting] = useState(false);

  const onDelete = useCallback(async () => {
    if (!userToModify || !orgId || !group) {
      return;
    }
    setIsDeleting(true);
    try {
      const { group: updatedGroup, error } = await deactivateUserRequest({
        orgId,
        userEmail: userToModify.email,
        groupId: group.id,
      });

      if (updatedGroup) {
        setMembers((members) =>
          members.filter((u) => u.email !== userToModify.email),
        );
        setUserToModify(undefined);
        setDeleteModalOpen(false);
        sendSuccessUINotification({
          message: `Remove ${nameWithEmail(
            userToModify.name,
            userToModify.email,
          )} from "${updatedGroup?.name}" group`,
        });
      }
      if (error) {
        setDeleteError(error ?? "Failed to remove user");
      }
    } catch (e: any) {
      setDeleteError(e?.message ?? "Failed to remove user");
    }
    setIsDeleting(false);
  }, [userToModify, orgId, group]);

  const onDeleteCancel = useCallback(() => {
    setDeleteModalOpen(false);
    setUserToModify(undefined);
  }, []);

  const isAssignRolesEnabled = useFeatureFlag(
    Flag.ENABLE_RBAC_ROLE_ASSIGNMENTS,
  );

  return (
    <>
      <div className={SearchContainer}>
        <SearchInput
          placeholder="Search"
          onChange={onSearchChangeDebounced}
          data-test="user-search-input"
        />
        <AddButton
          text="Add member"
          onAdd={() => setAddModalOpen(true)}
          disabled={!canEditGroupMembers}
          disabledText="You do not have permission to add group members"
        />
      </div>
      <RecommendedTable<MemberToRender>
        data={filteredMembers}
        dataLabel="users"
        uniqueKey="email"
        columns={columns}
        actionMenuItems={menuItems}
        // using totalCount to avoid frequent update when search which could cause crash
        paginationOptions={members.length > 10 ? { pageSize: 10 } : undefined}
        loading={groupLoading}
      />
      <DeleteModal
        open={deleteModalOpen}
        title={"Remove user"}
        onCancel={onDeleteCancel}
        onDelete={onDelete}
        error={deleteError}
        isDeleting={isDeleting}
        dataTestName="user"
        confirmText="Remove"
      >
        User <b>{userToModify?.name}</b> will be removed from group{" "}
        <b>{group?.name}</b>
      </DeleteModal>
      <InviteOrgUserModal
        isVisible={addModalOpen && group?.type === GroupType.ALL_USERS}
        onClose={onAddCancel}
        onUsersAdded={onUsersAdded}
      />
      {group &&
        (isAssignRolesEnabled ? (
          <NewMemberModalV2
            isModalOpen={addModalOpen && group?.type !== GroupType.ALL_USERS}
            setIsModalOpen={setAddModalOpen}
            invitees={invitees}
            group={group}
            setMembers={setMembers}
            allUsers={orgUsers}
          />
        ) : (
          <NewMemberModal
            isModalOpen={addModalOpen && group?.type !== GroupType.ALL_USERS}
            setIsModalOpen={setAddModalOpen}
            invitees={invitees}
            group={group}
            setMembers={setMembers}
            allUsers={orgUsers}
          />
        ))}
    </>
  );
};

export default MemberList;
