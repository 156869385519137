import { GroupMemberStatus, UserType } from "@superblocksteam/shared";
import { Tooltip } from "antd";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { DeleteModal } from "components/ui/Modal";
import RecommendedTable, { RecColumn } from "components/ui/RecommendedTable";
import { MANAGE_ROLE_MEMBERS, MANAGE_USERS } from "constants/rbac";
import { useFeatureFlag } from "hooks/ui";
import { useAuthorizationCheck } from "hooks/ui/rbac/useAuthorizationCheck";
import { SUPPORT_EMAIL_ADDRESS } from "legacy/constants/routes";
import { getCurrentUser } from "legacy/selectors/usersSelectors";
import { RoleDropdown } from "pages/Permissions/RoleDropdown";
import { Flag } from "store/slices/featureFlags";
import { selectOnlyOrganization } from "store/slices/organizations";
import { nameWithEmail } from "store/utils/group";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import { sendSuccessUINotification } from "utils/notification";
import { deactivateUserRequest } from "./client";
import { UserToRender, formatDate } from "./constants";

const NameStyle = styleAsClass`
  max-width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

type ColType = RecColumn<UserToRender>;

const renderTime = ({ value }: { value: Date | undefined }) =>
  value ? (
    <div>{formatDate(value)}</div>
  ) : (
    <div style={{ color: colors.GREY_200 }}>–</div>
  );

const renderStatus = ({ value }: { value: string }) => (
  <div style={{ textTransform: "capitalize" }}>{value}</div>
);

const UserList = ({
  type,
  users,
  setUsers,
  loading,
  totalCount,
  groupId,
  updateUser,
}: {
  type: UserType;
  users: UserToRender[];
  setUsers: React.Dispatch<React.SetStateAction<UserToRender[]>>;
  loading: boolean;
  totalCount: number;
  groupId: string;
  updateUser: (userId: string, updates: Partial<UserToRender>) => void;
}) => {
  const [userToModify, setUserToModify] = useState<UserToRender | undefined>(
    undefined,
  );

  const currentUser = useSelector(getCurrentUser);

  const [canEditUser, canManageUserRoles] = useAuthorizationCheck([
    MANAGE_USERS,
    MANAGE_ROLE_MEMBERS,
  ]);

  const removeTooltip = useCallback(
    (member: UserToRender) => {
      if (!canEditUser) {
        return `You do not have permission to remove users.`;
      }

      if (member.status === GroupMemberStatus.DEACTIVATED) {
        return `Contact Superblocks Support at ${SUPPORT_EMAIL_ADDRESS} to reactivate this user.`;
      }

      if (currentUser?.id && member.email === currentUser?.email) {
        return `You cannot remove yourself from the organization.`;
      }
      return "";
    },
    [canEditUser, currentUser?.email, currentUser?.id],
  );

  const roleAssignmentsEnabled = useFeatureFlag(
    Flag.ENABLE_RBAC_ROLE_ASSIGNMENTS,
  );

  const onRoleUpdated = useCallback(
    (userId: string, updates: Record<string, string | null>) => {
      updateUser(userId, updates);
    },
    [updateUser],
  );

  const renderRole = useCallback(
    ({
      value,
      row,
    }: {
      value: string;
      row: { original: Record<string, any> };
    }) => {
      return (
        <div style={{ marginLeft: "-12px" }}>
          <Tooltip
            title={
              !canManageUserRoles
                ? "You do not have permission to assign roles"
                : ""
            }
          >
            <RoleDropdown
              currentRole={value}
              currentRoleName={row.original.roleName}
              principalId={row.original.id}
              principalType="user"
              onRoleChanged={onRoleUpdated}
              isDisabled={!canManageUserRoles}
              currentRoleAssignmentId={row.original.roleAssignmentId}
            />
          </Tooltip>
        </div>
      );
    },
    [onRoleUpdated, canManageUserRoles],
  );

  const columns: ColType[] = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ value, cell }) => (
          <div className={NameStyle}>
            {cell.row.original.email === currentUser?.email
              ? `${value} (You)`
              : value}
          </div>
        ),
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Last login",
        accessor: "lastLogin",
        Cell: renderTime,
        hidden: type !== UserType.EXTERNAL,
      },
      ...(roleAssignmentsEnabled
        ? ([
            {
              Header: "Role",
              accessor: "roleId",
              Cell: renderRole,
              hidden: type === UserType.EXTERNAL,
            },
          ] as ColType[])
        : []),
      {
        Header: "Status",
        accessor: "status",
        Cell: renderStatus,
        hidden: type === UserType.EXTERNAL,
      },
    ],
    [currentUser?.email, type, roleAssignmentsEnabled, renderRole],
  );

  const menuItems = useCallback(
    (user: UserToRender) => {
      const tooltip = removeTooltip(user);
      return [
        {
          key: "remove-user",
          label: (
            <Tooltip title={tooltip}>
              <div>
                {user.status === "active"
                  ? "Deactivate user"
                  : user.status === "deactivated"
                  ? "Reactivate user"
                  : "Remove invite"}
              </div>
            </Tooltip>
          ),
          onClick: () => {
            setUserToModify(user);
            setDeleteModalOpen(true);
          },
          "data-test": "delete-user-button",
          disabled: Boolean(tooltip),
        },
      ];
    },
    [removeTooltip],
  );

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const currentOrg = useSelector(selectOnlyOrganization);

  const [deleteError, setDeleteError] = useState<string>();
  const [isDeleting, setIsDeleting] = useState(false);
  const onDelete = useCallback(async () => {
    if (!userToModify) {
      return;
    }
    setIsDeleting(true);
    try {
      const { group, error } = await deactivateUserRequest({
        orgId: currentOrg.id,
        userEmail: userToModify.email,
        groupId,
      });
      if (group) {
        if (userToModify.status === "invited") {
          setUsers((users) =>
            users.filter((u) => u.email !== userToModify.email),
          );
        } else {
          updateUser(userToModify.id, {
            status: GroupMemberStatus.DEACTIVATED,
          });
        }
        setUserToModify(undefined);
        setDeleteModalOpen(false);
        sendSuccessUINotification({
          message:
            userToModify.status === "invited"
              ? "Invite was revoked"
              : `Deactivate ${nameWithEmail(
                  userToModify.name,
                  userToModify.email,
                )}`,
        });
      }
      if (error) {
        setDeleteError(error ?? "Failed to delete user");
      }
    } catch (e: any) {
      setDeleteError(e?.message ?? "Failed to delete user");
    }
    setIsDeleting(false);
  }, [currentOrg.id, groupId, setUsers, updateUser, userToModify]);

  const onCancel = useCallback(() => {
    setDeleteModalOpen(false);
    setUserToModify(undefined);
  }, []);

  return (
    <>
      <RecommendedTable<UserToRender>
        data={users}
        dataLabel="users"
        uniqueKey="email"
        columns={columns}
        actionMenuItems={
          type === UserType.SUPERBLOCKS && canEditUser ? menuItems : undefined
        }
        // using totalCount to avoid frequent update when search which could cause crash
        paginationOptions={totalCount > 10 ? { pageSize: 10 } : undefined}
        loading={loading}
      />
      <DeleteModal
        open={deleteModalOpen}
        title={
          userToModify?.status === "active"
            ? "Deactivate user"
            : "Remove invite"
        }
        onCancel={onCancel}
        onDelete={onDelete}
        error={deleteError}
        isDeleting={isDeleting}
        dataTestName="user"
        confirmText={
          userToModify?.status === "active" ? "Deactivate" : "Remove"
        }
      >
        User <b>{userToModify?.name}</b>
        {userToModify?.status === "active"
          ? ` will be deactived from your
        organization.`
          : `'s invite will be removed from your organization.`}
      </DeleteModal>
    </>
  );
};

export default UserList;
